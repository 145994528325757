// Section image
.img-apps {
  height: auto;
  width: 100%;
  max-height: 130vh;
  transition: right 0.75s ease-out;

  .on-screen & {
    @apply right-0 md:right-8;
  }
}

.apps {
  &-content {
    transition: left 0.75s ease-out;
    .on-screen & {
      @apply left-0;
    }
  }

  &-link {
    @apply bg-white text-black rounded-full w-10 h-10 flex items-center justify-center;
    transition: all 0.5s ease;
    svg path {
      transition: fill 0.5s ease;
    }

    &:hover,
    &:focus {
      @apply bg-pink;
      svg path {
        @apply fill-white;
      }
    }
  }

  &-item {
    @apply bg-gold-20 p-5 border-l-4 border-solid border-gold-40 flex flex-col justify-between;

    &--desc {
      @apply text-xs;
    }
  }
}
