// Logo
@keyframes blink {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.r3k {
  @apply w-80 max-w-full mx-auto sm:mx-0;

  &__white {
    fill: $white;
    opacity: 0%;

    .on-screen &--r {
      animation: blink 2s ease-in 0s 1, blink 2s ease-in 3s 1;
    }
    .on-screen &--3 {
      animation: blink 2s ease-in 0.5s 1, blink 2s ease-in 2.5s 1;
    }
    .on-screen &--k {
      animation: blink 2s ease-in 1s 1;
    }
  }
}

// Section image
// .img {
//   &-intro {
//     height: 140vh;
//     width: auto;
//   }
// }

// .intro-text {
//   transition: left 0.75s ease-out;

//   .on-screen & {
//     @apply left-0;
//   }
// }
