/* Normalize */
@import "~normalize.css/normalize.css";

// Settings
@import "./imports/settings.scss";
@import "./imports/mixins.scss";

/* Generic */
html {
  height: 100%;
}

html,
body {
  box-sizing: border-box;
}
body {
  font-family: Alata, sans-serif;
  font-size: $base-font-scale + em;
  line-height: 1.4;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

// Modules
@import "../modules/apps/css/global.scss";
@import "../modules/blabla/css/global.scss";
@import "../modules/contact/css/global.scss";
@import "../modules/header/css/global.scss";
@import "../modules/intro/css/global.scss";
@import "../modules/sections/css/global.scss";
