// Section wrapper
main > section {
  @apply p-6 w-full min-h-100vh;
}

// Section toggle buttons
.section-toggle {
  @apply flex justify-center items-center fixed right-5 w-12 h-12 rounded-full bg-white z-20;
  transition: all 0.75s ease-out;

  &__next {
    @apply -bottom-12 opacity-0;

    .window-load & {
      @apply bottom-6 opacity-100;
    }
    &.off-screen {
      @apply -bottom-12 opacity-0;
    }
  }

  &__prev {
    @apply -top-12 opacity-0;

    .window-load & {
      @apply top-6 opacity-100;
    }
    &.off-screen {
      @apply -top-12 opacity-0;
    }
  }

  svg {
    width: pxToRem(30);
    height: pxToRem(30);
  }
}

// Section slideins
.slide-in {
  position: relative;

  &--from-left {
    transition: left 0.75s ease-out;
    left: -100vw;

    .on-screen & {
      left: 0;
    }
  }

  &--from-right {
    transition: right 0.75s ease-out;
    right: -100vw;

    .on-screen & {
      right: 0;
    }
  }
}
