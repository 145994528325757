.img-contact {
  @apply absolute bottom-auto top-0 md:-bottom-1/2 md:top-auto -right-6 h-50vw md:h-140vw w-auto ml-auto -mb-6 md:mb-0;
}

.contact {
  &-content {
    transition: left 0.75s ease-out;

    .on-screen & {
      @apply left-0;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
