.img-blabla {
  @apply absolute bottom-auto top-0 md:-bottom-1/2 md:top-auto -right-6 h-50vw md:h-140vw w-auto ml-auto -mb-6 md:mb-0;
}

.blabla {
  &-content {
    transition: left 0.75s ease-out;
    .on-screen & {
      @apply left-0;
    }
  }
  &-list {
    @apply list-none p-0 m-0 grid grid-cols-1 gap-4;
  }
  &-list-item {
    @apply bg-cyan p-5 border-l-4 border-solid border-cyan-60 flex flex-col justify-between;
  }
}
